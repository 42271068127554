import React from 'react'
import { Layout } from "../components/layout"
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import {
    productCardStyle,
    productHeadingStyle,
    productImageStyle,
    productDetailsStyle,
    productTitleStyle
  } from "../components/product-card.module.css"
import {listingContainerStyle } from "../components/product-listing.module.css"

export const query = graphql`
    query {
        allShopifyCollection(sort: {order: DESC, fields: title}) {
            edges {
              node {
                description
                handle
                title
                id
                image {
                  altText
                  originalSrc
                  gatsbyImageData(
                      width: 500
                      layout: CONSTRAINED
                        )
                }
                products {
                    productType
                }
              }
            }
          }
    }
`

const Card = ({description, title, image, searchQuery}) => {
    return (
        <Link to={searchQuery} className={[productCardStyle, "animatedDivPulse"].join(" ")}>
            <div className={productImageStyle} data-name="product-image-box">
                <GatsbyImage imgStyle={{borderRadius: "0"}} image={image.gatsbyImageData} alt={image.altText} />
            </div>
            <div className={productTitleStyle}>
                <h2 as="h2" className={productHeadingStyle}>{title}</h2>
                <p>{description}</p>
            </div>
        </Link>
        )
}

const CardList = ({collections}) => {
    let collectionList = [];

    if(collections) {
        collectionList = collections.map(({node}) => {
            const { description, title, id, image, products} = node;
            const type = products[0];
            let searchQuery=''
            if(type) {
                searchQuery = "/search?p=" + type.productType
            } else {
                searchQuery = "/search"
            }
            return (
                    <Card key={id} description={description} title={title} id={id} image={image} searchQuery={searchQuery}  />
            )
        })
    } else {
        collectionList = null;
    }

    return <div className={listingContainerStyle}>{collectionList}</div>
}

export default function CollectionsPage ({data}) {
    
    const collections = data?.allShopifyCollection?.edges;

    return (
        <Layout>

            <h1 className="title">Products</h1>
            <CardList collections={collections} />
        </Layout>
    )
}